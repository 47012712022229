<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="group_2 flex-row">
        <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd9950c3c6e4bb1e930ec47e78c813ac81c89a7bee475d1239718ec59ca1e6a31.png"
        />
        <span class="text_1">Ágil Finanzas</span>
        <span class="text_2" @click="gopage(1)">INICIO</span>
        <span class="text_3" @click="gopage(2)">Acerca de Nosotros</span>
        <span class="text_4" @click="gopage(3)">Política privacidad</span>
        <span class="text_5" @click="gopage(4)">Centro de Ayuda</span>
      </div>
      <div class="group_3 flex-row"><div class="block_1 flex-col"></div></div>
      <div class="text-wrapper_1 flex-row"><span class="text_6">Política de Privacidad</span></div>
      
    </div>
    <div class="group_4 flex-col">
      
       

      Bienvenido a la aplicación Ágil Finanzas (en adelante "nosotros" o "Ágil Finanzas"). En esta era digital,
        proteger su información personal es una de nuestras principales prioridades. Somos conscientes de la importancia
        de la privacidad personal para usted, por lo que hemos establecido una estricta política de privacidad para
        garantizar la seguridad y el manejo adecuado de su información.<br><br>

        Esta política de privacidad cubre cómo recopilamos, usamos, procesamos y protegemos su información personal,
        incluyendo, pero no limitado a, la información que proporciona al registrarse, usar nuestros servicios,
        participar en actividades de marketing o interactuar con nosotros. Nos comprometemos a recopilar y usar su
        información solo cuando cumpla con los requisitos legales y con su consentimiento, y únicamente dentro del
        alcance necesario para proporcionar servicios de calidad, mejorar la experiencia del usuario y garantizar la
        seguridad de las transacciones.
        <br><br>

        Al leer esta política de privacidad, le pedimos que comprenda las medidas de protección de su información
        personal y los derechos relacionados que puede ejercer. Usted tiene derecho a acceder, corregir y eliminar su
        información personal en cualquier momento, y puede optar por limitar la forma en que procesamos su información.
        Tomaremos medidas técnicas y de gestión activas para proteger la seguridad de su información y prevenir el
        acceso no autorizado o la divulgación.
        <br>

        <div class="t">1.Recopilación y Uso de Información</div>
        Recopilamos y utilizamos los siguientes tipos de información para proporcionar y mejorar nuestros servicios:
        <br>
        <div class="t">Información Personal</div>
        Cuando los usuarios utilizan nuestros servicios, es posible que solicitemos proporcionar información personal,
        como nombre, dirección, dirección de correo electrónico y número de teléfono, entre otros. Usaremos esta
        información para procesar las solicitudes, transacciones y brindar soporte al cliente.

        <div class="t">Información Financiera</div>
        Para evaluar su puntaje de crédito, necesitamos recopilar datos sobre su situación financiera, incluyendo, pero
        no limitándose a, ingresos, gastos, deudas y activos, para garantizar la entrega segura y precisa de los
        préstamos.

        <div class="t">Información de SMS</div>
        Cuando los usuarios utilizan los servicios de Ágil Finanzas, con la autorización del usuario, Ágil Finanzas necesita recopilar, transmitir y almacenar la información de los mensajes SMS del usuario, incluyendo contenido, remitente y estado de lectura, para evaluar el crédito del usuario y realizar análisis antifraude, asegurando así la seguridad del usuario. Sin su permiso, esta información no se compartirá con terceros ni se utilizará para otros fines, y se subirá encriptada a nuestro centro de seguridad https://permo.agilp6res3tapp.com/ilp6re/ para garantizar la seguridad de la información.

        <div class="t">Información de la Lista de Contactos</div>
        Cuando utiliza los servicios de Ágil Finanzas, Ágil Finanzas necesita recopilar, transmitir y almacenar la información de los contactos de emergencia seleccionados por el usuario de su lista de contactos, incluyendo nombre, número de teléfono y otros datos de contacto. Esto nos ayuda a confirmar su identidad y evaluar riesgos. Protegeremos estrictamente su privacidad y seguridad. Esta información de contacto solo se utilizará para evaluaciones de crédito y riesgo, y no para otros fines. Ágil Finanzas solo obtendrá esta información con la autorización explícita del usuario, y los datos se subirán a https://permo.agilp6res3tapp.com/ilp6re/. Sin el permiso del usuario, no se compartirán con terceros.

        <div class="t">Permisos de Cámara</div>
        Para verificar su identidad, puede autorizarnos a acceder a su cámara para tomar fotos o videos con el fin de
        realizar verificaciones faciales y de documentos. Este permiso solo se utiliza para la recopilación de datos
        durante el uso de la aplicación. Si no activa este permiso, no podrá usar las funciones de carga y
        reconocimiento de imágenes, pero no afectará el uso de otros servicios.

        <div class="t">Información GAID</div>
Nuestra aplicación Ágil Finanzas recopila su Identificador de publicidad de Google (GAID), un identificador de publicidad del dispositivo proporcionado por Google, para fines de análisis y publicación de anuncios. Podemos utilizar GAID para mostrarle contenido publicitario personalizado para brindarle recomendaciones de productos o servicios que sean relevantes para usted. Puede desactivar o restablecer GAID a través de la configuración de su dispositivo.

<div class="t">Registro de Llamadas (Call Log)</div>
Cuando los usuarios se registran o inician sesión en una cuenta, Ágil Finanzas recopila la información del registro de llamadas almacenada en su dispositivo y verifica los números en el registro de llamadas mediante la realización de llamadas, para determinar si el usuario ha recibido las llamadas y así identificar riesgos de fraude y asegurar la cuenta. Solo recopilaremos información del registro de llamadas (como la duración de la llamada, el número de la llamada y el estado de la llamada) después de obtener la autorización explícita del usuario, transmitiéndola y subiéndola a nuestros servidores (https://permo.agilp6res3tapp.com/ilp6re/). No compartiremos sus datos personales con terceros.

<div class="t">Fotos y Videos</div>
Necesitamos acceder a los permisos de fotos del usuario para ayudarle a subir las imágenes necesarias y los datos faciales durante el proceso de préstamo. Esta información solo se utiliza con fines de recopilación en la aplicación, ayudándonos a confirmar la identidad del usuario y a evaluar su puntaje de crédito mediante datos autorizados de terceros. Las imágenes del documento de identidad y las imágenes faciales recopiladas se transmitirán y subirán a nuestros servidores (https://permo.agilp6res3tapp.com/ilp6re/). No compartiremos sus datos personales con terceros.     

<div class="t">Información de Cuentas Sociales</div>
Necesitamos recopilar información relacionada con las cuentas sociales del usuario para comprender sus preferencias y desarrollar y ofrecer servicios personalizados según sus gustos. Esta información se encriptará y subirá a nuestro centro de seguridad (https://permo.agilp6res3tapp.com/ilp6re/) para su protección.

<div class="t">Lista de Aplicaciones</div>
Cuando utiliza Ágil Finanzas, necesitamos acceder a la lista de aplicaciones instaladas en el dispositivo del usuario (incluido el nombre de la aplicación instalada, el nombre del paquete, la hora de instalación, la hora de actualización, etc.) para brindar recomendaciones y sugerencias más personalizadas. Además, este acceso nos permite realizar revisiones para prevenir fraudes y evaluar situaciones de riesgo. Este proceso está diseñado para detectar posibles amenazas de malware y garantizar la integridad del sistema y mantener la calidad de nuestros servicios de préstamo. La información recopilada se enviará al servidor (https://permo.agilp6res3tapp.com/ilp6re/) cifrada y mantenida. No compartiremos sus datos personales con terceros.<div class="t">Información de Ubicación</div>
Para asegurar que nuestros servicios funcionen de manera eficiente y proporcionar una conexión precisa, determinando si el área del usuario ofrece los servicios relevantes, podemos obtener información aproximada de la ubicación del usuario. Si no habilita los permisos de ubicación, esto afectará su capacidad para utilizar los servicios de préstamos.

<div class="t">Información del Dispositivo</div>
Para brindar un mejor servicio y soporte, podemos obtener información sobre el dispositivo del usuario, incluyendo el modelo del dispositivo, la dirección IP, el tipo de navegador, la versión del sistema operativo, el operador y el fabricante. Esta información nos ayuda a entender el rendimiento y la compatibilidad de su dispositivo para asegurar que nuestra aplicación funcione correctamente en su dispositivo.


        <br>

        <div class="t">2.Propósitos de la Recolección y Almacenamiento de Datos</div>
        Recopilamos y almacenamos su información personal principalmente para los siguientes propósitos:
        <br><br>
        (1) Provisión y Administración de Servicios<br>
        Utilizamos su información personal para verificar su identidad, procesar solicitudes de préstamo, completar
        transacciones de pago y proporcionar otros servicios relacionados, asegurando una gestión y operación efectivas
        de nuestra aplicación.
        <br><br>
        (2) Mejora de la Experiencia del Usuario<br>
        Analizamos sus hábitos de uso y datos de comportamiento para comprender y evaluar las necesidades, preferencias
        e intereses de los usuarios, con el fin de optimizar nuestros servicios, funciones y contenidos, mejorando así
        la experiencia del usuario.
        <br><br>
        (3) Garantía de Seguridad<br>
        Utilizamos su información personal para proteger la seguridad de los usuarios, prevenir fraudes y otras
        actividades ilegales, asegurando la seguridad e integridad de las transacciones.
        <br><br>
        (4) Cumplimiento de Requisitos Legales y Normativos<br>
        Podemos necesitar usar y almacenar su información personal para cumplir con las leyes, regulaciones o requisitos
        administrativos aplicables.
        <br><br>
        (5) Soporte al Cliente y Comunicación<br>
        Utilizamos su información de contacto para enviarle notificaciones de servicios, soporte técnico y otras
        comunicaciones necesarias, manteniendo un contacto efectivo con usted.
        <br><br>
        (6) Marketing<br>
        Con su consentimiento, podemos utilizar su información personal para realizar actividades de marketing,
        recomendarle productos o servicios relevantes y ofrecer recomendaciones y anuncios personalizados.<br><br>

        (7) Otros Propósitos Legales<br>
        Podemos utilizar su información personal para otros fines comerciales legítimos, con su consentimiento o según
        lo permitido por la ley.
        <br>

        <div class="t">3. Cómo Protegemos su Información Personal</div>
        Valoramos la privacidad de los usuarios y nos comprometemos a proteger la seguridad de su información personal.
        Para garantizar la seguridad de sus datos, hemos implementado las siguientes medidas:<br><br>

        (1) Tecnología de Seguridad de Datos:<br>
        Utilizamos tecnologías avanzadas de seguridad y métodos de encriptación para proteger su información personal
        contra accesos no autorizados, uso o divulgación. Esto incluye, entre otros, el cifrado de datos, control de
        acceso, auditorías de seguridad y mecanismos de autenticación.
        <br><br>
        (2) Control de Acceso:<br>
        Solo autorizamos a empleados y socios que necesiten acceder a su información personal, quienes están obligados a
        cumplir estrictamente con la confidencialidad. Realizamos capacitaciones periódicas en concienciación de
        seguridad y reforzamos la protección de datos.
        <br><br>
        (3) Medidas de Protección de Datos:<br>
        Hemos establecido políticas estrictas y controles para la protección de datos, asegurando el cumplimiento de las
        leyes y regulaciones aplicables en la recopilación, procesamiento y almacenamiento de información, implementando
        medidas técnicas y organizativas necesarias para mitigar riesgos.
        <br><br>
        (4) Evaluación y Monitoreo de Riesgos:<br>
        Realizamos evaluaciones regulares de riesgos de seguridad y auditorías del sistema para detectar y abordar
        amenazas de seguridad de manera oportuna. Implementamos mecanismos de monitoreo y respuesta en tiempo real para
        prevenir posibles incidentes de seguridad.
        <br><br>
        (5) Cooperación con Terceros:<br>
        Suscribimos estrictos acuerdos de protección de datos con socios y proveedores de servicios, asegurando el
        cumplimiento de nuestros estándares de seguridad y compartiendo datos solo cuando sea necesario.<br><br>

        (6) Protección de Derechos de los Usuarios:<br>
        Respetamos y apoyamos sus derechos para acceder, corregir, eliminar y restringir el procesamiento de su
        información personal. Puede comunicarse con nuestro servicio al cliente en cualquier momento y responderemos y
        gestionaremos sus solicitudes de manera oportuna.
        <br><br>
        Nos comprometemos a cumplir siempre con las leyes y regulaciones aplicables, así como con los estándares de la
        industria, para garantizar la seguridad y la privacidad de su información personal. Cuando su información
        personal ya no sea necesaria, nos aseguraremos de eliminarla o anonimizarla por completo.
        <br><br>
        A pesar de nuestros esfuerzos por proteger su información personal, es importante que tome medidas activas para
        proteger la seguridad de su información, como usar contraseñas complejas, cambiarlas regularmente y no compartir
        su cuenta o información personal con terceros.
        <br><br>
        Ágil Finanzas se compromete a hacer de la protección de la privacidad y seguridad de los datos de los usuarios
        su máxima prioridad en todo momento. Continuaremos implementando rigurosas medidas de protección de datos para
        garantizar el manejo seguro y adecuado de su información personal.
        <br><br>

        <div class="t">4.Compartición y Divulgación de Información</div>

        Sin su consentimiento expreso, no utilizaremos sus datos personales para fines comerciales o de marketing, ni
        los compartiremos con terceros. Al aceptar esta política de privacidad, compartiremos sus datos personales en
        las siguientes circunstancias:
        <br><br>
        (1)Provisión, Personalización y Mejora de Servicios: Podemos utilizar la información relevante recopilada para
        proporcionar, personalizar y mejorar nuestros servicios. Nos comprometemos a no compartir, alquilar ni vender su
        información personal de manera ilegal.
        <br><br>
        (2)Socios y Proveedores de Servicios: Podemos compartir la información personal necesaria con socios y
        proveedores de servicios seleccionados y autorizados estrictamente para que nos ayuden a brindar servicios de
        soporte, como análisis de datos, soporte al cliente y servicios de infraestructura tecnológica.
        <br><br>
        (3)Requisitos Legales y Cumplimiento: Podemos divulgar su información personal según lo exijan las leyes, los
        reglamentos legales, las órdenes judiciales o los requisitos regulatorios gubernamentales aplicables. Esto puede
        incluir, entre otros, la seguridad pública, procedimientos legales o litigios legales.
        <br><br>
        (4)Reorganización Empresarial y Fusiones: En caso de participar en una reorganización empresarial, fusión o
        transferencia de activos, su información personal puede transferirse como parte de la transacción. En tales
        casos, haremos todos los esfuerzos razonables para proteger la seguridad y confidencialidad de su información
        personal.
        <br><br>
        (5)Consentimiento o Autorización del Usuario: Con su consentimiento o autorización, podemos compartir su
        información personal con terceros. Usted puede retirar su consentimiento en cualquier momento, si bien esto no
        afectará la legalidad del procesamiento basado en su consentimiento previo.
        <br><br>
        Nos comprometemos a compartir su información personal solo dentro de los límites permitidos por la ley y a
        firmar acuerdos de confidencialidad con terceros, exigiéndoles que implementen medidas de seguridad similares a
        las nuestras para proteger su información personal. No venderemos su información personal a ningún tercero sin
        su consentimiento.
        <br><br>

        <div class="t">5.Cookies</div>
        Utilizamos cookies para recopilar información. Las cookies son archivos que contienen pequeños datos que pueden
        incluir identificadores anónimos. Las cookies se envían a su navegador y se almacenan en su computadora. Puede
        configurar su navegador para rechazar todas las cookies o para indicar cuándo se envía una cookie. Sin embargo,
        si elige rechazar las cookies, es posible que no pueda utilizar algunas partes de nuestros servicios.
        <br>

        <div class="t">6.Protección Especial para Menores de Edad</div>

        Bajo esta política, salvo disposición legal en contrario, los menores de edad se refieren a personas naturales
        menores de 18 años. Cumplimos estrictamente con las leyes y las mejores prácticas de la industria para proteger
        la privacidad y la información personal de los menores de edad. Solo procesamos información sobre menores de
        edad cuando la ley lo permite y con el consentimiento expreso de los padres o tutores. Los menores de edad no
        están autorizados a utilizar nuestros productos.


        <div class="t">7. Derechos de Privacidad</div>

        Usted tiene los siguientes derechos de privacidad:<br>

        Derecho de Acceso y Rectificación: Tiene derecho a acceder y solicitar una copia de sus datos personales. Si sus
        datos son incorrectos o están incompletos, tiene derecho a solicitar su corrección o actualización.<br><br>

        Derecho de Supresión: En cumplimiento con las leyes aplicables, si decide dejar de utilizar esta aplicación,
        puede eliminarla completamente de su dispositivo móvil en cualquier momento. Esto eliminará todos los datos y
        configuraciones relacionados con la aplicación. Tenga en cuenta que cierta información puede ser necesaria para
        proporcionar el servicio y no puede ser evitada mediante cambios en la configuración o la eliminación de la
        aplicación. Para solicitar la eliminación de sus datos personales, utilice la configuración de la aplicación o
        contacte a nuestro equipo de atención al cliente.<br><br>

        Derecho de Preferencia: Puede gestionar sus preferencias personales a través de la configuración de la
        aplicación o del dispositivo móvil, haciendo las selecciones correspondientes en la configuración de la
        aplicación.<br><br>

        Retirada del Consentimiento: Tiene derecho a retirar su consentimiento en cualquier momento con respecto a la
        información personal proporcionada bajo consentimiento. La retirada del consentimiento no afectará la legalidad
        del procesamiento basado en el consentimiento previo.


        <div class="t">8. Revocación del Consentimiento
        </div>
        Usted tiene el derecho de revocar en cualquier momento el consentimiento que ha proporcionado a nuestra empresa.
        Siga los procedimientos y métodos establecidos por la empresa para llevar a cabo la revocación. Es importante
        destacar que, a menos que existan circunstancias especiales donde no se pueda revocar el consentimiento, la
        revocación no afectará la recopilación, uso o divulgación de información personal realizada dentro del alcance
        de su consentimiento previo.
        <br><br>
        Puede presentar una solicitud de eliminación de información personal en los siguientes casos, y responderemos
        activamente:
        <br>
        (1)Si violamos leyes o acuerdos con usted al recopilar o utilizar información personal.<br>
        (2)Si violamos leyes o acuerdos con usted al compartir o transferir su información personal con terceros,
        detendremos estas actividades de inmediato y notificaremos a los terceros para eliminar la información
        relevante.<br>
        (3)Si violamos leyes o acuerdos con usted al revelar públicamente su información personal, detendremos la
        divulgación pública de inmediato y publicaremos avisos para solicitar a las partes pertinentes que eliminen la
        información correspondiente.<br>
        (4)Si ya no utiliza nuestros productos o servicios, o si cierra su cuenta, o si terminamos el servicio y las
        operaciones.<br><br>

        Tenga en cuenta que la revocación del consentimiento puede hacer que la empresa no pueda proporcionarle ciertos
        servicios o mantener servicios continuos. Nos comprometemos a hacer todo lo posible para proteger sus derechos
        de privacidad en todas las circunstancias.


        <div class="t">9.Actualización y Notificación de la Política de Privacidad</div>

        Con el fin de mejorar nuestros servicios, satisfacer las necesidades de desarrollo del negocio, cumplir con
        nuevos requisitos legales y ajustar nuestros productos o servicios, nos reservamos el derecho de actualizar esta
        política en cualquier momento. En caso de actualización, le notificaremos de manera apropiada (como a través de
        ventanas emergentes, avisos destacados en la página, notificaciones activas en la aplicación, correos
        electrónicos/mensajes de texto, anuncios en el sitio web oficial, entre otros) para que esté informado/a de
        cualquier cambio en la política. Nos comprometemos a no disminuir sus derechos bajo esta política sin su
        consentimiento explícito.


        <div class="t">10.Contáctenos</div>

        Si tiene alguna pregunta, preocupación o duda sobre nuestra política de privacidad o el manejo y protección de
        su información personal, no dude en contactarnos. Estaremos encantados de brindarle apoyo y ayuda. <br><br>

        Correo electrónico: kljkdfg435546@gmail.com<br>
        Dirección: C 23 54 4 H COL SANTA CRUZ AVIACION 15640 VENUSTIANO CARRANZA, D.F.<br>
        Horario de trabajo: lunes a sábado de 09:00 a 18:00<br><br>

        Agradecemos su lectura de nuestra política de privacidad. Valoramos su privacidad y continuaremos esforzándonos
        para asegurar que su información personal sea completamente protegida y respetada. Nuevamente, gracias por usar
        nuestros servicios.

      
    </div>
    <div class="group_5 flex-col">
      <div class="text-wrapper_4 flex-row">
        <span class="text_11">Ágil Finanzas</span>
        <span class="text_12">Contáctanos</span>
        <span class="text_13">DIRECCIÓN</span>
      </div>
      <div class="block_2 flex-row">
        <div class="text-wrapper_5">
          <span class="paragraph_5">
            <span @click="gopage(1)">Inicio</span> 
            <br />
            <span @click="gopage(2)">Acerca de Nosotros</span> 
            <br />
            <span @click="gopage(3)">
              Política de privacidad
            </span>
            <br />
            <span class="" @click="gopage(4)">Centro de Ayuda</span>
            </span>
        </div>
        <img
          class="thumbnail_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng937bbc3faf36dc78acfd7668a6b932cfe698f70aade87f831c8e3a3d20de2a96.png"
        />
        <div class="group_6 flex-col justify-between">
          
          <div class="image-text_2 flex-row justify-between">
            <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngfe4a82261da913a31b60b0e4e8a6f579de869c412f3f7f355954aa8eb38f8329.png"
            />
            <span class="text-group_2">Correo Electrónico: kljkdfg435546@gmail.com</span>
          </div>
        </div>
        <div class="image-text_3 flex-row justify-between">
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng76f69a10cfde10bc273937827fddef127065d252638b93b0a6de1f11b642bbee.png"
          />
          <span class="text-group_3">
            C 23 54 4 H COL SANTA CRUZ AVIACION <br> 15640 VENUSTIANO CARRANZA,D.F.
          </span>
        </div>
      </div>
      <div class="block_3 flex-row">
        <div class="image-text_4 flex-row justify-between">
          <img
            class="label_3"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng94cd62c6e69ecfe69637915271cf66036dc4783acae82b225ff35b17954bdff0.png"
          />
          <span class="text-group_4">Horario de Atención al Cliente: 9:00 - 18:00</span>
        </div>
      </div>
      <img
        class="thumbnail_2"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng937bbc3faf36dc78acfd7668a6b932cfe698f70aade87f831c8e3a3d20de2a96.png"
      />
      <img
        class="thumbnail_3"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng937bbc3faf36dc78acfd7668a6b932cfe698f70aade87f831c8e3a3d20de2a96.png"
      />
      <img
        class="thumbnail_4"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng937bbc3faf36dc78acfd7668a6b932cfe698f70aade87f831c8e3a3d20de2a96.png"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {
    gopage(type){
      switch (type) {
        case 1:
          this.$router.push('./page_1')
          break;
          
          case 2:
          this.$router.push('./page_2')
          break;
          
          case 3:
          this.$router.push('./page_3')
          break;

          case 4:
          this.$router.push('./page_4')
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style scoped lang="less" src="./assets/index.response.less" />